/**
 * @file
 * Contains payment method form submit component.
 */
import React, { useState, useEffect, useMemo } from 'react';
import ButtonElement from 'common/components/ButtonElement/ButtonElement';
import InputAmountElement from 'common/components/InputElement/InputAmountElement';
import InputElement from 'common/components/InputElement/InputElement';
import {
  ConversionRate,
  FeeAndConversionContainer,
  FeeAndConversionWrapper,
  ProcessingFee,
  ProcessingFeeCrypto,
  MainAmountTitle,
  MaxAmount,
  MinMaxAmount,
  PaymentAmountDescription,
  AmountInputWrapper,
  UnderAmountLine,
  ErrorArea,
  ConversionHintWrapper,
  MinAmount,
} from '../FastPaymentFlowMethods.style';
import { AmountFormItemElement } from '../FastPaymentFlowMethods.style';
import { inject, observer } from 'mobx-react';
import { roundTo } from 'common/utils/roundNumber.util';
import { FLOW_TYPE_MAPPING } from 'common/constants/flowType.constant';
import { amountValidation } from 'common/utils/formValidation.util';
import FormItemElement from 'common/components/FormItemElement/FormItemElement';
import Spin from 'common/components/Spinner/Spin';
import PopoverElement from 'common/components/PopoverElement/PopoverElement';
import { roundNumberHigh, roundNumberLow } from 'common/utils/roundNumber.util';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { API_V3, MAIN_API_URL } from 'common/constants/apiEnpoints.constants';
import useQuery from 'common/hooks/useQuery.hook';
import jwt_decode from 'jwt-decode';
import { calculateProcessingFee } from 'common/utils/calculateFeeWithComission';

const PaymentMethodFormSubmit = (props) => {
  const {
    data: {
      propsAmount,
      flowType,
      paymentMethodData,
    },
    form,
    appStore,
    paymentStore,
  } = props;

  const { setFieldsValue } = form;

  /**
   * Use query params.
   */
  const { token } = useQuery(['token']);

  /**
   * Use translation.
   */
  const { t } = useTranslation();

  /**
   * Submit button flag.
   */
  const [active, setActive] = useState(false);

  /**
   * Amount.
   */
  const [amount, setAmount] = useState(0);

  /**
   * Total amount.
   */
  const [totalAmount, setTotalAmount] = useState(0);

  /**
   * Processing fee.
   */
  const [processingFee, setProcessingFee] = useState(0);

  /**
   * Min amount value.
   */
  const minAmount = roundNumberHigh(paymentMethodData?.min_amount || 0, 0);

  /**
   * Max amount value.
   */
  const maxAmount = roundNumberLow(paymentMethodData?.max_amount || 0, 0);

  /**
   * Exchange rate.
   */
  const [exchangeRate, setExchangeRate] = useState(0);

  /**
   * Exchange currency.
   */
  const [currency, setCurrency] = useState('');

  /**
   * Preloader flag.
   */
  const [preloader, setPreloader] = useState(false);

  /**
   * Error amount.
   */
  const [errorAmount, setErrorAmount] = useState('');

  /**
   * Api version.
   */
  const [apiVersion, setApiVersion] = useState(0);

  /**
   * Delivery currency code.
   */
  const [deliveryCurrencyCode, setDeliveryCurrencyCode] = useState('');

  /**
   * Delivery currency symbol.
   */
  const [deliveryCurrencySymbol, setDeliveryCurrencySymbol] = useState('');

  /**
   * Delivery amount
   */
  const [deliveryAmount, setDeliveryAmount] = useState(0);

  /**
   * Auto submit state
   */
  const [autoSubmit, setAutoSubmit] = useState(true);

  /**
   * Set api version.
   */
  useEffect(() => {
    const { apiVersion } = appStore.mainAttributes;
    apiVersion && setApiVersion(apiVersion);
  }, [appStore.mainAttributes]);

  /**
   * Validate fields before submit
   */
  const validateFields = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      const errorFieldsList = (e?.errorFields || [])?.map((item) => item?.name?.[0] || null);
      paymentStore.setFieldsErrors(errorFieldsList);
    }
  }

  /**
   * Reset fields when payment method changed.
   */
  useEffect(() => {
    if (paymentStore.getTransactionAnswerFieldError === 0) {
      const addPrefillFields = Object.entries(appStore.getMainAttributes?.prefillFields || {}).reduce((acc, [k, v]) => {
	      if (v) {
		      return { ...acc, [k]: v || '' }
	      }
	      return acc;
      }, {});

      if (!propsAmount) {
        form.resetFields();
        form.setFieldsValue({
          currency: currency,
          ...addPrefillFields
        })
        setAmount(0);
        setTotalAmount(0);
      } else {
        form.resetFields();
        form.setFieldsValue({
          customer_amount: `${currency}${propsAmount || amount}`,
          amount: flowType === FLOW_TYPE_MAPPING.deposit ? totalAmount : propsAmount,
          currency: currency,
          ...addPrefillFields
        });
        flowType === FLOW_TYPE_MAPPING.deposit ? setAmount(totalAmount) : setAmount(propsAmount || amount);
      }

      !!Object.keys(addPrefillFields || {})?.length && validateFields();
      setErrorAmount('');
    }
  }, [paymentMethodData, currency, paymentStore.getTransactionAnswerFieldError]);

  /**
   * Set main currency.
   */
  useEffect(() => {
    const {currencyAsset} = appStore.getMainAttributes || {};
    const {
      method_currency,
      method_symbol,
    } = paymentMethodData || {};

    const paymentMethodCurrency = method_symbol
      ? method_symbol
      : method_currency
        ? `${method_currency} `
        : null;
    const v2Currency = currencyAsset?.symbol
      ? currencyAsset?.symbol
      : currencyAsset?.code
        ? `${currencyAsset?.code} `
        : null;
    setCurrency(paymentMethodCurrency || v2Currency);
  }, [appStore.getMainAttributes, paymentMethodData]);

  /**
   * Set delivery currency.
   */
  useEffect(() => {
    if (apiVersion > 0) {
      const {
        delivery_currency,
        delivery_symbol,
      } = paymentMethodData;
      const filterCurrency = delivery_currency && delivery_currency.split(':');

      setDeliveryCurrencySymbol(delivery_symbol ? delivery_symbol : '');
      filterCurrency && setDeliveryCurrencyCode(filterCurrency[1]);
      const deliveryCurrency = filterCurrency && filterCurrency[filterCurrency.length - 1];
      form.setFieldsValue({
        deliveryCurrency,
      });

      if (apiVersion > 0 && paymentStore.getTransactionAnswerFieldError === 0) {
        setDeliveryAmount(0);
        setProcessingFee(0);
      }
    }
  }, [paymentMethodData, form, setFieldsValue, apiVersion]);

  /**
   * Set default fields value.
   */
  useEffect(() => {
    if (totalAmount) {
      form.setFieldsValue({
        amount: flowType === FLOW_TYPE_MAPPING.deposit ? totalAmount : (propsAmount || amount),
        customer_amount: `${currency}${propsAmount || amount}`,
      })
    }
  }, [form, totalAmount, amount, setFieldsValue]);

  /**
   * Calculate total amount.
   */
  useEffect(() => {
    const {
      customer_fee_fixed: feeFixed,
      customer_fee_percent: feePercent,
      method_currency,
    } = paymentMethodData || {};

    const amountValue = propsAmount || amount;

    const feeData = { amount: amountValue, feePercent, feeFixed };

    const processingFee = calculateProcessingFee(feeData);

    const processingFeeRounded = processingFee && roundTo(processingFee, 2);

    const { currencyAsset, amount: settingsAmount } = appStore.getMainAttributes || {};

    const activeCondition = (!!maxAmount && amount > maxAmount)
      || amountValue === 0
      || amountValue < minAmount
      || amount <= 0
      || deliveryAmount < 0
      || !amount
      || !amountValue
      || !!(flowType === FLOW_TYPE_MAPPING.remit
        && (method_currency || currencyAsset?.code) === deliveryCurrencyCode
        && (settingsAmount && ((settingsAmount - processingFeeRounded) < minAmount)));
    setActive(activeCondition);

    processingFee && form.setFieldsValue({
      minAmount: minAmount,
      maxAmount: maxAmount,
      processingFee: processingFeeRounded,
    });

    const validationData = {
      inputValue: amount,
      maxAmount,
      minAmount,
      currencySymbol: currency,
      processingFee: processingFeeRounded,
      flowType,
      ...(propsAmount && { propsAmount }),
    };

    validateErrors(validationData);
  }, [
    appStore,
    propsAmount,
    amount,
    paymentMethodData,
    currency,
  ]);

  /**
   * Get delivery amount and fee.
   */
  useEffect(() => {
    if (apiVersion > 0 && 'guid' in paymentMethodData) {
      propsAmount && getTransactionData(propsAmount);
    }
  }, [propsAmount, apiVersion, paymentMethodData]);

  /**
   * Get exchange rate.
   */
  useEffect(() => {
    paymentMethodData.rate && setExchangeRate(paymentMethodData.rate);
  }, [propsAmount, appStore, paymentStore, currency, paymentMethodData, paymentStore.getFormFieldsData]);

  /**
   * Validate errors.
   * @param {object} data.
   * Parameters to validate.
   */
  const validateErrors = (data) => {
    const error = amountValidation(data);

    switch (error) {
      case 'min':
        return setErrorAmount('min');
      case 'max':
        return setErrorAmount('max');
      case 'processing_fee_error':
        return setErrorAmount('processing_fee_error');
      default:
        return setErrorAmount('');
    }
  };

  /**
   * Render fee.
   */
  const renderFee = () => {
    return (
      (
        paymentMethodData.rate !== 1
        && (apiVersion > 0 || paymentMethodData.payment_group === 'CRYPTO')
      ) ? (
        <FeeAndConversionContainer>
          {exchangeRate ? (
            <FeeAndConversionWrapper>
              <div>
                {processingFee > 0 ? (
                  <ProcessingFeeCrypto processingFeeError={errorAmount}>
                    {t('payment.method.processingFee')} {currency}{processingFee}
                  </ProcessingFeeCrypto>
                ) : (
                  <ProcessingFee />
                )}
                {paymentMethodData.payment_group === 'CRYPTO' ? (
                  <ConversionRate>
                    <div>
                      {t('payment.method.conversion.rate')} {exchangeRate}
                    </div>
                    <ConversionHintWrapper>
                      <PopoverElement data={paymentMethodData} placement="top" text={t('fpf.payment.method.conversion.rate')} />
                    </ConversionHintWrapper>
                  </ConversionRate>
                ) : (
                  <ConversionRate>
                    <div>
                      {t('payment.method.conversion.rate')} {exchangeRate}
                    </div>
                    <ConversionHintWrapper>
                      <PopoverElement data={paymentMethodData} placement="top" text={t('fpf.payment.method.conversion.rate')} />
                    </ConversionHintWrapper>
                  </ConversionRate>
                )}
              </div>
              {!!maxAmount && (
                <MinMaxAmount>
                  <MinAmount error={errorAmount}>{t('payment.method.minAmount')} {currency}{minAmount}</MinAmount> / <MaxAmount error={errorAmount}>{t('payment.method.maxAmount')} {currency}{maxAmount}</MaxAmount>
                </MinMaxAmount>
              )}
            </FeeAndConversionWrapper>
          ) : (
            <FeeAndConversionWrapper/>
          )}
        </FeeAndConversionContainer>
      ) : (
        <UnderAmountLine>
          {
            processingFee > 0 ? (
              <>
                <ProcessingFee processingFeeError={errorAmount}>{t('payment.method.processingFee')} {currency}{processingFee}</ProcessingFee>
              </>
            ) : (
              <ProcessingFee height={12}/>
            )
          }
          <PaymentAmountDescription>
            {!!maxAmount && (
              <MinMaxAmount>
                <MinAmount error={errorAmount}>{t('payment.method.minAmount')} {currency}{minAmount}</MinAmount> / <MaxAmount error={errorAmount}>{t('payment.method.maxAmount')} {currency}{maxAmount}</MaxAmount>
              </MinMaxAmount>
            )}
          </PaymentAmountDescription>
        </UnderAmountLine>
      )
    )
  };

  /**
   * Get transaction data.
   * @param {string} amount.
   * Amount value number.
   */
  const getTransactionData = async (amount) => {
    const value = propsAmount ? propsAmount : amount.replace(/[^\d.]/g, '');
    setPreloader(true);

    const {
      customer_fee_fixed,
      customer_fee_percent,
      digits_asset,
      digits_delivery,
      rate,
    } = paymentMethodData;

    try {
      const { apiEndpoints } = appStore.getMainAttributes;

      const data = {
        amount: Number(value),
        fee_percent: customer_fee_percent,
        fee_fixed: customer_fee_fixed,
        digits_asset,
        digits_delivery,
        rate,
      };
      const tokenData = token && jwt_decode(token);
      const merchantId = tokenData?.payload?.merchant_guid;
      const reqUrl = apiEndpoints.direct_payment_server || `${MAIN_API_URL}${API_V3}/${merchantId}`;
      const response = await paymentStore.setTxAmountAndFeeData(reqUrl, data, token);
      setDeliveryAmount(response.delivery_amount);
      setProcessingFee(response.fee);
      setAmount(Number(value));

      form.setFieldsValue({
        amount: response.amount,
        deliveryAmount: response.delivery_amount,
      });
      setPreloader(false);

      //Submit form if all field
      const oneMethod = Object.keys(paymentStore.getPaymentMethodsData || {})?.length === 1;
      if(oneMethod && !!appStore.getMainAttributes?.amount) {
        const selectedMethod = Object.keys(paymentStore.getPaymentMethodsData).map(m => m)?.[0];
        const prefillFields = Object.entries(appStore.getMainAttributes?.prefillFields || {}).reduce((fields, [k, v]) => {
					if (v) {
						return [...fields, k];
					}
					return fields;
        }, []);
        const fieldsList = Object.entries(paymentStore.getPaymentMethodsData?.[selectedMethod]?.fields || {}).reduce((acc, [k, v]) => {
          if(v?.hidden) {
            return acc;
          } else {
            return [...acc, k]
          }
        }, []);

        const checker = (arr, target) => target.every(v => arr.includes(v));
        if (checker(prefillFields, fieldsList) && autoSubmit) {
          setAutoSubmit(false);
          form.submit();
        }
      }
    } catch (e) {
      console.error(e);
      setPreloader(false);
    }
  };

  /**
   * Handler to get fee and tx amount.
   */
  useEffect(() => {
    (async () => {
      if (paymentStore.getTransactionAnswerFieldError > 0) {
        await getTransactionData(String(amount));
      }
    })();
  }, [paymentStore.getTransactionAnswerFieldError])

  /**
   * Get transaction data debounce handler.
   */
  const debounceHandler = useMemo(() => {
    return debounce(getTransactionData, 500);
  },[paymentMethodData]);

  /**
   * Render submit button.
   */
  const renderSubmitButton = () => {
    const amount = `${deliveryCurrencySymbol || `${deliveryCurrencyCode} `}${deliveryAmount > 0 ? deliveryAmount : 0}`;

    const SUBMIT_BUTTON = {
      deposit_method: {
        default: <ButtonElement
          disabled={active}
          htmlType="submit"
        >
          {t('payment.method.pay')} {amount}
        </ButtonElement>,
        CRYPTO: <ButtonElement
          loading={false}
          disabled={active}
          htmlType="submit"
        >
          {t('payment.method.pay.btc')} {amount}
        </ButtonElement>,
      },
      remit_method: {
        default: <ButtonElement
          disabled={active}
          htmlType="submit"
        >
          {t('payment.method.receive')} {amount}
        </ButtonElement>,
        CRYPTO: <ButtonElement
          loading={false}
          disabled={active}
          htmlType="submit"
        >
          {t('payment.method.receive.btc')} {amount}
        </ButtonElement>,
      },
    };

    return (
      <>
        {!!SUBMIT_BUTTON[flowType] && !!paymentMethodData.payment_group && (SUBMIT_BUTTON[flowType][paymentMethodData.payment_group] || SUBMIT_BUTTON[flowType].default)}
      </>
    )
  };

  return (
    <Spin spinning={preloader}>
      <FormItemElement
        name="minAmount"
        key="minAmount"
        hidden
      >
        <InputElement />
      </FormItemElement>
      <FormItemElement
        name="maxAmount"
        key="maxAmount"
        hidden
      >
        <InputElement />
      </FormItemElement>
      <FormItemElement
        name="processingFee"
        key="processingFee"
        hidden
      >
        <InputElement />
      </FormItemElement>
      <FormItemElement
        name="deliveryAmount"
        key="deliveryAmount"
        hidden
      >
        <InputElement />
      </FormItemElement>
      <FormItemElement
        name="deliveryCurrency"
        key="deliveryCurrency"
        hidden
      >
        <InputElement />
      </FormItemElement>
      {
        (currency) && (
        <AmountInputWrapper>
          <MainAmountTitle>{t('payment.method.amount')}</MainAmountTitle>
          <AmountFormItemElement
            key="customer_amount"
            name="customer_amount"
            validateTrigger="onChange"
            rules={[
              () => ({
                validator (rule, value) {
                  const validationData = {
                    inputValue: value,
                    maxAmount,
                    minAmount,
                    currencySymbol: currency,
                    processingFee,
                    flowType,
                    ...(propsAmount && { propsAmount }),
                  };

                  const error = validateErrors(validationData);
                  if (!error) {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <InputAmountElement
              bordered={false}
              readOnly={!!propsAmount}
              propsAmount={propsAmount}
              callbackFn={(value) => debounceHandler(value)}
              digitAsset={paymentMethodData.digits_asset}
              currency={currency}
              error={errorAmount}
            />
          </AmountFormItemElement>
        </AmountInputWrapper>
      )}


      {
        (currency) && renderFee()
      }

      {
        (deliveryCurrencyCode || deliveryCurrencySymbol) && renderSubmitButton()
      }

      <ErrorArea>
        {
          {
            'min': t('payment.method.errors.min.amount'),
            'max': t('payment.method.errors.max.amount'),
            'processing_fee_error': t('payment.method.errors.max.processingFee'),
          }[errorAmount]
        }
      </ErrorArea>
    </Spin>
  )
};

export default inject('paymentStore', 'appStore')(observer(PaymentMethodFormSubmit));
